import { Link } from "gatsby";
import Img from "gatsby-image/index";
import React from "react";
import shape10 from "../../../assets/images/shape/vector-shape10.png";
import shape9 from "../../../assets/images/shape/vector-shape9.png";
import DangerousMarkdownContent from "../DangerousMarkdownContent/DangerousMarkdownContent";

const CallToAction = ({ link, title, buttonText, text, image }) => {
  return (
    <div className="project-start-area bg-color ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="project-start-image">
              <Img
                alt={title}
                style={{
                  height: "100%",
                  width: "100%",
                  maxHeight: 300,
                }}
                imgStyle={{
                  objectFit: "contain",
                }}
                fluid={image.childImageSharp.fluid}
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="project-start-content">
              <h2>{title}</h2>
              <p>
                <DangerousMarkdownContent content={text} />
              </p>

              <Link to={link} className="default-btn">
                <i className="flaticon-web"></i>
                {buttonText}
                <span></span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="vector-shape9">
        <img src={shape9} alt="about" />
      </div>
      <div className="vector-shape10">
        <img src={shape10} alt="about" />
      </div>
    </div>
  );
};

export default CallToAction;

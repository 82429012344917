import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import PageBanner from "../components/Common/PageBanner";
import Layout from "../timewise-custom/components/App/Layout";
import Navbar from "../timewise-custom/components/App/Navbar";
import SEO from "../timewise-custom/components/App/SEO";
import FaqCallToAction from "../timewise-custom/components/CallToAction/FaqCallToAction";
import DangerousMarkdownContent from "../timewise-custom/components/DangerousMarkdownContent/DangerousMarkdownContent";
import { markdownUnwrapper } from "../utils/markdown-unwrapper";

const FAQ = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { fields: [frontmatter___de___priority], order: ASC }
        filter: { frontmatter: { de: { template: { eq: "faq" } } } }
      ) {
        edges {
          node {
            frontmatter {
              de {
                title
                description
              }
              en {
                title
                description
              }
            }
          }
        }
      }
    }
  `);
  const { language, t } = useI18next();
  const faqs = markdownUnwrapper(data, language);
  const questionPrefix = language === "de" ? "F" : "Q";

  return (
    <Layout>
      <SEO title={t("navigation:faq")} />
      <Navbar />
      <PageBanner
        pageTitle={t("navigation:faq")}
        homePageText={t("navigation:home")}
        homePageUrl="/"
        activePageText={t("navigation:faq")}
      />
      <div className="ptb-100">
        <div className="container">
          <div className="faq-accordion">
            <Accordion>
              {faqs.map((faq, idx) => (
                <AccordionItem key={idx}>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      {`${questionPrefix}${idx + 1}`}. {faq.title}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      <DangerousMarkdownContent content={faq.description} />
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
      <FaqCallToAction />
    </Layout>
  );
};

export default FAQ;

import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import CallToAction from "./CallToAction";
import { markdownUnwrapper } from "../../../utils/markdown-unwrapper";
const FaqCallToAction = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {
          frontmatter: { de: { template: { eq: "faq-call-to-action" } } }
        }
      ) {
        edges {
          node {
            frontmatter {
              de {
                title
                image {
                  childImageSharp {
                    fluid(maxWidth: 500, quality: 70) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                text
                buttonText
                link
              }
              en {
                title
                text
                buttonText
                link
              }
            }
          }
        }
      }
    }
  `);
  const { language } = useI18next();
  const faqCallToAction = markdownUnwrapper(data, language)[0];
  return <CallToAction {...faqCallToAction} />;
};

export default FaqCallToAction;
